<template>
	<page>
		<module enable-flex>
			<empty class="flex-grow" icon="build" message="正在建设中，敬请期待！"></empty>
		</module>
	</page>
</template>

<script>
	import empty from '@/components/public/empty.vue'
	
	export default {
		components: {
			empty
		}
	}
</script>

<style lang="stylus" scoped>
</style>